// Common breadcrumb object to be shared across all components to send breadcrumbs to sentry
export interface BreadcrumbProps {
  [key: string]: BreadcrumbObject
}


export interface BreadcrumbObject {
    'Vehicle assignment':  {
    'Selected Plan': string,
    'Hub': string,
    'Odometer': string,
    'Customer Started Journey': Date,
    }
    
    'Personal details': {
    'Customer First Name': string;
    'Customer Middle Name': string;
    'Customer Last Name': string;
    'Customer Email': string;
    'Customer Mobile': string;
    'Specified Alt Name': string;
    'Customer Gender': string;
    'Customer Nationality': string;
    'Hirer ABN': string;
    }

    'Driver licence': {
    'Driver Licence Number': string,
    'Province': string,
    'Driver Licence Expiry': string,
    'Date of Birth': string,
    }
    
    'Agreement schedule': {
    'Execution Date': string,
    'Subscription Start Date': string,
    'Plan Length': string;
    'Chargebee Plan': string;
    'Additional Driver Fee': string;
    'Total Time Spent on Journey': string;
    }

    'NSW EV Subscriptions' : {
    'NSW Addendum Plan': string;
    'Weekly Subs without Incentive': number | null;
    'NSW Goverment Incentive': number | null;
    'Weekly Subs with Incentive': number;
    }

    'Cancellation' : {
      'Cancellation Start Time': Date;
      'Cancellation End Time': Date;
    }

    'Payment Holidays' : {
      'Payment Holiday Start Time': Date;
      'Payment Holiday End Time': Date;
    }
    
  }

 const breadcrumbObject: BreadcrumbObject = {
  "Vehicle assignment" : {
    'Selected Plan': '',
    'Hub': '',
    'Odometer': '',
    'Customer Started Journey': new Date()
  },
  'Personal details': {
    'Customer First Name': '',
    'Customer Middle Name': '',
    'Customer Last Name': '',
    'Customer Email': '',
    'Customer Mobile': '',
    'Specified Alt Name': '',
    'Customer Gender': '',
    'Customer Nationality': '',
    'Hirer ABN': '',
  },
  'Driver licence': {
    'Driver Licence Number': '',
    'Province': '',
    'Driver Licence Expiry': '',
    'Date of Birth': '',
  },
  'Agreement schedule': {
    'Execution Date': '',
    'Subscription Start Date': '',
    'Plan Length': '',
    'Chargebee Plan': '',
    'Additional Driver Fee': '',
    'Total Time Spent on Journey': ''
  },
  'NSW EV Subscriptions' : {
    'NSW Addendum Plan': '',
    'Weekly Subs without Incentive': 0,
    'NSW Goverment Incentive': 0,
    'Weekly Subs with Incentive': 0,
  },
  'Cancellation' : {
    'Cancellation Start Time': new Date(),
    'Cancellation End Time': new Date(),
  },
  'Payment Holidays' : {
    'Payment Holiday Start Time': new Date(),
    'Payment Holiday End Time': new Date(),
  }

}

export default breadcrumbObject;

