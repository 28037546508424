import { Country } from "@utils/constants/localisation";
import { IMSTokenParams, getWithIDToken } from "@utils/http/MSAuthAPI";


export interface IPlan {
    id: string
    name: string
    additionalDistCostPerUnit: number
    weeklyDistAllowance: number
    minimumPeriodWeeks: number
    weeklyHireFee: number
    NSWIncentiveTotalValue: number
    NSWIncentivePlanBeforeDiscount: number
};


interface IPlanService {
    getPlans(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IPlan[]>;
};


export const PlanService: IPlanService = {
    async getPlans(qParams: object, country: Country, IDTokenParams: IMSTokenParams): Promise<IPlan[]> {
        const resp = await getWithIDToken(
            `/v1/plans`,
            IDTokenParams,
            { ...qParams, country }
        );

        return resp.data.map((item: any) => ({
            id: item.id,
            name: item.name,
            additionalDistCostPerUnit: Number(item.additional_dist_cost_per_unit),
            weeklyDistAllowance: Number(item.weekly_distance_allowance),
            minimumPeriodWeeks: Number(item.minimum_period_weeks),
            weeklyHireFee: Number(item.weekly_hire_fee),
            NSWIncentiveTotalValue: Number(item.nsw_ev_incentive_total),
            NSWIncentivePlanBeforeDiscount: Number(item.nsw_ev_incentive_value_before_discount),
        }));
    },
};
