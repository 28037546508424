import { Country } from "@utils/constants/localisation";


export const createIntList = (start: number, len: number, step = 1) => (
  Array.from({ length: len }, (dummy, i) => start + (i * step))
);


// Abstract comparator covers null and undefined.
export const isNotNullNotUndefined = (variable: any): boolean => variable != null;


/**
 * Converts date object to string date, with the ISO format YYYY-MM-DD.
 * 
 * @param date Date object
 * @returns string date
 */
export const dateToISOFormat = (date: Date): string =>
  date.toLocaleDateString("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });


/**
 * Converts date or string object to string date, with the ISO format DD MMM YYYY.
 * The caller must ensure an accepted format by Date constructor for the date
 * argument if it's a string.
 * 
 * @param date Date or String object
 * @returns string date in format DD MMM YYYY
 */
export const formatDateToDD_MMM_YYYY = (date: Date | string): string =>
  new Date(date).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });


/**
 * 
 * @param dateString string date expected in the ISO format of YYYY-MM-DD
 * @returns string date of DD-MM-YYYY format
 */
export const flipDateFormat = (dateString: string): string => {
  const [year, month, day] = dateString.split("-");
  return `${day}-${month}-${year}`;
};


/**
 * Function that takes a start date object and adds the weeks number that the next Y years have.
 * 
 * @param startDate Date object
 * @param numYears number of years, of whose number of weeks will be added to startDate 
 * @returns Date object
 */
export const addNextYearsWeeks = (startDate: Date, numYears: number): Date => {
  const isLeapYear = (year: number): boolean => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

  const endDate = new Date(startDate);
  let currentYear = startDate.getFullYear();
  let totalWeeks = 0;

  while (numYears > 0) {
    const daysInYear = isLeapYear(currentYear) ? 366 : 365;
    totalWeeks += daysInYear / 7;

    numYears--;
    currentYear++;
  }

  const daysToAdd = Math.ceil(totalWeeks) * 7;
  endDate.setDate(endDate.getDate() + daysToAdd);

  return endDate;
};


export const getTimeDifference = (startDate: Date, endDate: Date) => {
  const timeDifferenceInMilliseconds: number = endDate.getTime() - startDate.getTime();

  const hours: number = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
  const remainingMillisecondsAfterHours: number = timeDifferenceInMilliseconds % (1000 * 60 * 60);

  const minutes: number = Math.floor(remainingMillisecondsAfterHours / (1000 * 60));
  const remainingMillisecondsAfterMinutes: number = remainingMillisecondsAfterHours % (1000 * 60);

  const seconds: number = Math.floor(remainingMillisecondsAfterMinutes / 1000);

  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString();
  const formattedSeconds = seconds.toString();

  return `${formattedHours} hours, ${formattedMinutes} minutes, ${formattedSeconds} seconds`;
};


export const countrySpecificCurrency = (country: string): String => 
  country === Country.AU ? "$" : "£";


export const countrySpecificDistanceUnit = (country: string): String => 
  country === Country.AU ? "km" : "miles";


//Matches only on the name returned from zoho user email as some users log into smart ops with a .com email
// but have a .com.au email stored in Zoho
export const emailMinusSplendDomain = (email: string) => {
  const emailString = email.toLowerCase();
  if (emailString.includes('splend.com')) {
    let splitEmail = emailString.split('@')[0];
    splitEmail = splitEmail.replace(/-uk|\.uk/g, '')
    splitEmail = splitEmail.replace(/-au|\.au/g, '')
    return splitEmail;
  }
  return email;
};



//Checks if the ending kms for the cancellation/test drive is greater than or equal to value
//of odometer in fleetio. This is because Fleetio update will fail if the input value is less than
//the odometer value in Fleetio
export const checkIfValidFleetioOdometerUpdate = (minValue: string, inputValue: string) =>
  parseFloat(inputValue) < parseFloat(minValue) ? false : true;


/**
 * Function to preserve just the digits from an alpha-numeric string.
 * 
 * @param value string representation of generic value
 * @returns string representation of only the digits from the input string
 */
export const keepDigits = (value: string): string => {
  value = value.replace(/[^0-9]/g, '');
  return value;
};


/**
 * Function to round a value to to 2dp
 * 
 * @param num string representation of generic value
 * @returns number rounded to 2 decimal places
 */
export const truncateToTwoDecimals = (num: string | number): number => {
  const parsedNum = typeof num === "string" ? parseFloat(num) : num;
  if (isNaN(parsedNum)) {
    throw new Error("Input must be a valid number or a string representing a number.");
  }
  return Math.floor(parsedNum * 100) / 100;
};

/**
 * Function to round a value to to 2dp
 * 
 * @param value string or number to be converted
 * @returns number rounded to 2 decimal places or if 0 a string rounded to 2 dp
 */
export const formatCurrency = (value: number | string): string => {
  if (value === 0 || value === '0') {
      return '0.00'; // Show 0 explicitly
  }
  const numericValue = Number(value);
  return !isNaN(numericValue) ? numericValue.toFixed(2) : ''; // Format with 2 decimal places or return empty
};
