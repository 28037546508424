import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { NavBar } from "@components/navigation/NavBar/NavBar";
import useMultiStepForm from "@hooks/useMultiStepForm";
import PersonalDetails from "../RAV/PersonalDetails";
import DriverLicence from "./DriverLicence";
import SubmitBtn from "@components/buttons/FormButtons/SubmitBtn";
import { useUserJourneyContext } from "@context/UserJourneyContext";
import { OnboardingService } from "@services/journeys/Onboarding";
import { Country } from "@utils/constants/localisation";
import { useAPIError } from "@context/ServerErrorContext";
import Warning from "@components/modal/info/Warning";
import ModalContainer from '@components/modal/Container';
import CircularSpinner from "@components/spinners/Circular/Circular";
import FlexiOwnPersonalDetails from "@views/FlexiOwn/FlexiOwnPersonalDetails";
import AddressDetails from "@views/journeys/AddressDetails";
import FlexiOwnEmergencyContacts from "@views/FlexiOwn/FlexiOwnEmergencyContacts";
import AgreementSchedule from "@views/FlexiOwn/AgreementSchedule";
import FlexiOwnUsedVehicleAddendum from "@views/FlexiOwn/UsedVehicleAddendum";
import NSWEVSubscriptions from "@views/FlexiOwn/NSWEVSubscriptions";
import FlexiPersonalDetails from "@views/Flexi/FlexiPersonalDetails";
import FlexiAgreementSchedule from "@views/Flexi/FlexiAgreementSchedule";
import RAVAgreementSchedule from "@views/RAV/RAVAgreementSchedule";
import { addBeforeUnloadListener, removeBeforeUnloadListener } from "@utils/refreshCatcher";
import RAVShortTermJourneyPersonalDetails from "@views/RAV/RAVShortTermJourneyPersonalDetails";
import { PlanOptions } from "@utils/planOptions";

import breadcrumbObject from "../../SmartOpsHome/breadcrumbObject";
import { createPayload } from "./OnboardingFormPayloadFactory";
import { DynamicPricingAU, DynamicPricingUK } from "@views/Initiatives/DynamicPricing";
import IgnitionView from "@views/Initiatives/IgnitionView";
import UKFOAgreementSchedule from "@views/UKFlexiOwn/UKFlexiOwnAgreementSchedule";


/**
 * Component that puts together the steps for all onboarding
 * journeys into what is a multi step form.
 *
 * Each step is a component that simply exposes some inputs.
 * There is a context that's shared across the entire journey,
 * meant to provide default values to the input fields (where
 * it is possible and justified). If any are updated, then the
 * new value will be stored into the context.
 *
 * When the user reaches the last step (having all mandatory fields
 * filled) and submits the form, the frontend will take the info
 * from the context, send it to the backend and will get the redirect
 * url to the contract signing page, which will be automatically
 * loaded once retrieved.
 *
 * If there will be any errors during the server calls, the error
 * context (that wraps all routes/pages) state will be set, making
 * a modal to be shown. The only option the user will have is an Ok
 * button, which once clicked, the user will be redirected to the
 * homepage, loosing all progress.
 */
export default function OnboardingForm() {

    const { formState: {
        carInfo,
        addressInfo,
        contractDetails,
        driverDetails,
        personalInfo,
        emergencyContactInfo,
        usedVehicleAddendum,
        splendInitiatives,
    } } = useUserJourneyContext();
    let breadCrumbObject = breadcrumbObject;

    const [loading, setLoading] = useState<Boolean>(false);
    const [stepNames, setStepNames] = useState<string[] | string>('');
    const [steps, setSteps] = useState<any>('');
    const [plan, setPlan] = useState<String[] | String>(['']);
    const [submitButtonText, setSubmitButtonText] = useState<string>('Next');

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { setErr, removeErr } = useAPIError();
    const navigate = useNavigate();

    const newCountry = driverDetails.country
    // The components included in the RAV journey
    const ravSteps: React.ReactElement[] = [
        <PersonalDetails breadcrumbObject={breadCrumbObject} />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <RAVAgreementSchedule breadcrumbObject={breadCrumbObject} />,
    ];

    // The names of each component to populate NavBar for RAV journey
    const ravStepNames: string[] = [
        'Personal details',
        'Driver licence',
        'Agreement schedule',
    ];

    const ravShortTermSteps: React.ReactElement[] = [
        <RAVShortTermJourneyPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <RAVAgreementSchedule breadcrumbObject={breadCrumbObject} />,
    ];

    // The names of each component to populate NavBar for RAV journey
    const ravShortTermStepNames: string[] = [
        'Personal details',
        'Contact details',
        'Driver licence',
        'Agreement schedule',
    ];

    const flexiSteps: React.ReactElement[] = [  // AU
        <FlexiPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <FlexiAgreementSchedule breadcrumbObject={breadCrumbObject} />,
    ];
    // The names of each component to populate NavBar for Flexi Own journey
    const flexiStepNames: string[] = [  // AU
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
    ];

    const flexiDynamicSteps: React.ReactElement[] = [  // AU
        <FlexiPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <FlexiAgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <DynamicPricingAU />
    ];
    // The names of each component to populate NavBar for Flexi Own journey
    const flexiDynamicStepNames: string[] = [  // AU
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'Dynamic Pricing Declaration',
    ];

    const flexiDynamicStepsUK: React.ReactElement[] = [
        <FlexiPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <FlexiAgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <DynamicPricingUK />
    ];
    // The names of each component to populate NavBar for Flexi Own journey
    const flexiDynamicStepNamesUK: string[] = [
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'Dynamic Pricing Declaration',
    ];

    const flexiIgnitionSteps: React.ReactElement[] = [  // UK
        <FlexiPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <FlexiAgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <IgnitionView />
    ];
    // The names of each component to populate NavBar for Flexi Own journey
    const flexiIgnitionStepNames: string[] = [ // UK
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'Splend Ignition programme',
    ];

    const flexiOwnSteps: React.ReactElement[] = [
        <FlexiOwnPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <AgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <NSWEVSubscriptions breadcrumbObject={breadCrumbObject} />,
    ];

    // The names of each component to populate NavBar for Flexi Own journey
    const flexiOwnStepNames: string[] = [
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'NSW EV Addendum',
    ];

    const flexiOwnUKSteps: React.ReactElement[] = [
        <FlexiOwnPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <UKFOAgreementSchedule breadcrumbObject={breadCrumbObject} />
    ];

    // The names of each component to populate NavBar for Flexi Own journey
    const flexiOwnUKStepNames: string[] = [
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule'
    ];

    const flexiOwnUsedSteps: React.ReactElement[] = [
        <FlexiOwnPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <AgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnUsedVehicleAddendum />,
        <NSWEVSubscriptions breadcrumbObject={breadCrumbObject} />,
    ];
    const flexiOwnUsedStepNames: string[] = [
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'Splend Flexi Own - Used vehicle',
        'NSW EV Addendum',
    ];

    const flexiOwnUsedUKSteps: React.ReactElement[] = [
        <FlexiOwnPersonalDetails breadcrumbObject={breadCrumbObject} />,
        <AddressDetails />,
        <DriverLicence breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnEmergencyContacts />,
        <UKFOAgreementSchedule breadcrumbObject={breadCrumbObject} />,
        <FlexiOwnUsedVehicleAddendum />
    ];
    const flexiOwnUsedUKStepNames: string[] = [
        'Personal details',
        'Address details',
        'Driver licence',
        'Emergency contacts',
        'Agreement schedule',
        'Splend Pre-loved',
    ];

    // Assigns set of steps depending on the journey/plan
    const selectJourneyFromPlan = (driverDetails: any) => {
        switch (driverDetails.plan) {
            case PlanOptions.RAVCreditHire:
                setSteps(ravSteps);
                setStepNames(ravStepNames);
                break;
            case PlanOptions.RAVSharecover:
                setSteps(ravSteps);
                setStepNames(ravStepNames);
                break;
            case PlanOptions.RAVShortTermRental:
                setSteps(ravShortTermSteps);
                setStepNames(ravShortTermStepNames);
                break;
            case PlanOptions.SplendFlexi:
                if (splendInitiatives.splendInitiative === "Dynamic Pricing") {
                    setSteps(flexiDynamicSteps);
                    setStepNames(flexiDynamicStepNames);
                    break;
                }
                else {
                    setSteps(flexiSteps);
                    setStepNames(flexiStepNames);
                    break;
                }
            case PlanOptions.SplendFlexiOwn:
                setSteps(flexiOwnSteps);
                setStepNames(flexiOwnStepNames);
                break;
            case PlanOptions.SplendRentToOwnUsedVehicle:
                setSteps(flexiOwnUsedSteps);
                setStepNames(flexiOwnUsedStepNames);
                break;
            case PlanOptions.UKFlexi:
                console.log("Plan option is UK Flexi");
                if (splendInitiatives.splendInitiative === "Ignition") {
                    console.log("Initiative is Ignition");
                    setSteps(flexiIgnitionSteps);
                    setStepNames(flexiIgnitionStepNames);
                    break;
                } else if (splendInitiatives.splendInitiative === "Dynamic Pricing") {
                    console.log("Initiative is Dynamic Pricing");
                    setSteps(flexiDynamicStepsUK);
                    setStepNames(flexiDynamicStepNamesUK);
                    break;
                }
                else {
                    console.error("Initiative is not set. Using Flexi Ignition UK");
                    setSteps(flexiIgnitionSteps);
                    setStepNames(flexiIgnitionStepNames);
                    break;
                }
            case PlanOptions.UKFlexiOwn:
                setSteps(flexiOwnUKSteps);
                setStepNames(flexiOwnUKStepNames);
                break;
            case PlanOptions.UKRentToOwnUsed:
                setSteps(flexiOwnUsedUKSteps);
                setStepNames(flexiOwnUsedUKStepNames);
                break;
        }
    };

    // Checks the value of the plan in the User Context
    useEffect(() => {
        setPlan(driverDetails.plan);
        selectJourneyFromPlan(driverDetails);
    }, [plan]);


    // The only purpose of this hook is to check whether a value that is required to progress through the onboarding form is present.
    // Country is being used as a proxy to see if the user is able to progress with the form, the value or type of country can change
    // and this will remain functional but there has to be a value for country present for this hook to work. If a page is refreshed then
    // the information in the context will be wiped, this hook will then see that change and redirect the user back to the homepage to
    // restart the journey.
    useEffect(() => {
        if (!driverDetails.country) {
            window.location.href = "/";
        }
    }, []);

    useEffect(() => {
        addBeforeUnloadListener(); // add event listener when starting the form
        return () => {
            removeBeforeUnloadListener(); // end event listener at the end of the form
        };
    }, []);

    // Checks email pulled from Zoho for null or invalid entries. Email length <= 4 has been
    // left in on purpose as a quick check to fix production issue but will need to be 
    // expanded on in the future
    const checkValidEmailAddress = () => personalInfo.email && personalInfo.email.length >= 4;

    const {
        currentStepIndex,
        step,
        isFirstStep,
        isLastStep,
        back,
        next
    } = useMultiStepForm(steps);


    const isPenultimateStep = () =>
        currentStepIndex == steps.length - 2;

    //Checks if this form should be the last step
    // Usually this returns isLastStep, but some overrides exist for addendum pages that depend on the data input during the form
    //Steps that have "optional" steps:
    // * FlexiOwn (AU)
    //     * NSW EV Addendum
    // * FlexiOwnUsed (AU)
    //     * NSW EV Addendum
    const checkIsLastStep = () => {
        // If:
        // We are on the penultimate step (might be the final step)
        // & of a journey with an optional NSWEV addendum,
        if (isPenultimateStep()) {
            if (JSON.stringify(stepNames) == JSON.stringify(flexiOwnStepNames)
                || JSON.stringify(stepNames) == JSON.stringify(flexiOwnUsedStepNames)) {

                if (
                    // NSW data, so we do want to go to actual last step
                    contractDetails.NSWIncentiveTotalValue != 0
                    || contractDetails.NSWIncentivePlanBeforeDiscount != 0) {
                    return false;
                } else {
                    // If plan contains no NSW data, then penultimate step is the last step
                    // (skipping NSW addendum step
                    return true;
                }
            }
        }

        // If not a journey with optional last step, then just return isLastStep
        return isLastStep;
    };

    useEffect(() => {
        // Update the submit button text.
        // Check whenever step changes,
        // or if NSWIncentiveTotalValue changes (as this can cahnge the result of checkIsLastStep)
        if (checkIsLastStep()) {
            setSubmitButtonText('Submit');
        } else {
            setSubmitButtonText('Next');
        }
    }, [currentStepIndex, contractDetails.NSWIncentiveTotalValue,]);

    const onSubmitForm = (event: FormEvent) => {
        // removeBeforeUnloadListener(); // end event listener at the end of the form

        event.preventDefault();

        // if the email address fails validation then display error and redirect to homepage
        if (!checkValidEmailAddress()) {
            setErr(
                true,
                {
                    msg: 'Please enter a valid email address on Zoho and try again',
                    UI: <InvalidEmailWarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                }
            );
        }

        // if it's not the last step, then go to the next one
        if (checkIsLastStep() === false) { next(); return; }

        setLoading(true);

        const payload = createPayload(
            driverDetails.plan,
            driverDetails,
            personalInfo,
            carInfo,
            contractDetails,
            addressInfo,
            emergencyContactInfo,
            usedVehicleAddendum,
            splendInitiatives,
        );

        OnboardingService.obtainCustomerSignUrl(
            payload,
            Country[newCountry],
            { msalContext, isAuthenticated }
        ).then((resp) => {
            removeBeforeUnloadListener(); // end event listener at the end of the form
            // go to signing page
            window.location.replace(resp.sign_url);
            setLoading(false);
        }).catch((err) => {
            // Display an error modal - by acknowledging the error and clicking Ok btn, the user is sent to homepage
            setErr(
                true,
                {
                    msg: 'Error with onboarding.',
                    UI: <WarningModal onClickClose={() => { removeErr(); navigate('/'); }} />
                }
            );
            setLoading(false);
        });
    };

    // For sending only the breadcrumbs related to the page to Sentry
    const getComponentName = (stepIndex: any, driverDetails: any) => {
        switch (driverDetails.plan) {
            case PlanOptions.RAVCreditHire:
                return ravStepNames[stepIndex];
            case PlanOptions.RAVSharecover:
                return ravStepNames[stepIndex];
            case PlanOptions.RAVShortTermRental:
                return ravShortTermStepNames[stepIndex];
            case PlanOptions.SplendFlexi:
                return flexiStepNames[stepIndex];
            case PlanOptions.SplendFlexiOwn:
                return flexiOwnStepNames[stepIndex];
            case PlanOptions.SplendRentToOwnUsedVehicle:
                return flexiOwnUsedStepNames[stepIndex];
        }
    };

    return (<>
        <NavBar pageTitle={stepNames[currentStepIndex]} backAction={!isFirstStep ? back : undefined} />
        <div style={{ margin: '2.5vh 2.5vw' }}>
            <form onSubmit={onSubmitForm}>
                <div style={{ top: '.5rem', right: '.5rem' }}>
                    <p><strong>Step {currentStepIndex + 1}</strong></p>
                    {step}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5vh 0' }}>
                    <SubmitBtn text={submitButtonText} breadcrumbObject={breadCrumbObject} componentName={getComponentName(currentStepIndex, driverDetails)} />
                </div>
            </form>
        </div>
        {loading && <ForegroundLoadingSpinner />}
    </>);
};


const WarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Something went wrong"
        additionalInfo={`We’re sorry, we were unable to process your request.  
        We will investigate the issue and in the meantime, please onboard the customer via an alternative method.`}
        onClickClose={onClickClose}
    />
);


const ForegroundLoadingSpinner = () => (
    <ModalContainer stylesCfg={{ windowTransparency: true }} >
        <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
            <CircularSpinner />
        </div>
    </ModalContainer>
);


const InvalidEmailWarningModal = ({ onClickClose }: { onClickClose: React.MouseEventHandler }) => (
    <Warning
        message="Invalid Email Address Detected"
        additionalInfo={`Please enter a valid email address on Zoho and try again.`}
        onClickClose={onClickClose}
    />
);
